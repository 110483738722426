<template>
  <div>
    <CRow>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <CCol class="row">
              <CCol col="11">
                {{ $lang.emailtemplate.crud.edit }}
              </CCol>
            </CCol>
          </CCardHeader>
          <CCardBody>
            <CAlert
                v-show="err_msg"
                color="danger"
                closeButton
                :show.sync="dismissCountDown"
                fade
            >
              {{ err_msg }}
            </CAlert>
            <CForm @submit.prevent="onSubmit">

              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.emailtemplate.title.$error }"
              >
                <label class="form__label"
                >{{ $lang.emailtemplate.form.title }}
                  <required_span/>
                </label>
                <input
                    type="text"
                    :placeholder="$lang.emailtemplate.form.title"
                    class="form-control"
                    v-model="emailtemplate.title"
                />
                <small
                    class="error"
                    v-if="$v.emailtemplate.title.$error && !$v.emailtemplate.title.required"
                >{{ $lang.emailtemplate.validation.required.title }}</small
                >
              </div>

              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.emailtemplate.subject.$error }"
              >
                <label class="form__label"
                >{{ $lang.emailtemplate.form.subject }}
                  <required_span/>
                </label>
                <input
                    type="text"
                    :placeholder="$lang.emailtemplate.form.subject"
                    class="form-control"
                    v-model="emailtemplate.subject"
                />
                <small
                    class="error"
                    v-if="$v.emailtemplate.subject.$error && !$v.emailtemplate.subject.required"
                >{{ $lang.emailtemplate.validation.required.subject }}</small
                >
              </div>


              <div
                  class="form-group"
                  :class="{ 'form-group--error': $v.emailtemplate.message.$error }"
              >
                <template>
                  <ckeditor v-model="emailtemplate.message" :config="editorConfig"></ckeditor>
                </template>
                <small
                    class="error"
                    v-if="
                    $v.emailtemplate.message.$error && !$v.emailtemplate.message.required
                  "
                >{{ $lang.emailtemplate.validation.required.message }}</small
                >
              </div>
              <div
                  class="form-group"
              >
                <label class="form__label"
                >{{ $lang.emailtemplate.form.status }}
                  <required_span/>
                </label>
                <v-select :options="statusOptions" v-model="emailtemplate.status">
                </v-select>

              </div>

              <CRow>
                <CCol col="6" class="text-left">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      :disabled="submitted"
                  >
                    <CIcon name="cil-check-circle"/>
                    {{ $lang.buttons.general.crud.update }}
                  </CButton>
                </CCol>
              </CRow>


            </CForm>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {emailTemplate, cmsMaster} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import CKEditor from 'ckeditor4-vue';

//import { Maxlength } from "../../store/maxlength";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
Vue.use(CKEditor);

export default {
  name: "EditEmailTemplate",
  mixins: [Mixin],
  components: {
    required_span
  },
  data() {
    return {
      submitted: false,
      editorConfig: {
        // The configuration of the editor.
      },
      adminId: "",
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      modules: [],
      statusOptions: [],
      cmsMasterPages: [],
      activePage: 1,
      module: emailTemplate,
      cmsMasterModule: cmsMaster,
      emailtemplate: {
        title: "",
        subject: "",
        message: "",
        status: "",
      },
    };
  },
  validations: {
    emailtemplate: {
      title: {
        required,
      },
      subject: {
        required,
      },
      message: {
        required,
      },
    },
  },
  created() {
    store.commit("showLoader", false); // Loader Off
  },
  mounted() {
    let self = this;
    self.statusOptions.push(
        {value: "Active", label: "Active"},
        {value: "InActive", label: "InActive"}
    );
    const id = this.$route.params.id;
    self.editId = id;
    axios
        .get(this.viewUrlApi(this.module, id))
        .then((response) => {
          if (response.data) {
            let responseData = response.data.data;
            self.emailtemplate.message = responseData.message;
            self.emailtemplate.title = responseData.email_title;
            self.emailtemplate.status = responseData.status;

            self.emailtemplate.status = {
              value: responseData.status,
              label: responseData.status,
            };

            self.emailtemplate.subject = responseData.subject;
            store.commit("showLoader", false); // Loader Off
          }
        })
        .catch(function () {
          store.commit("showLoader", false); // Loader Off
        });
    store.commit("showLoader", false); // Loader Off
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    onSubmit() {
      let self = this;
      this.$v.emailtemplate.$touch();
      if (this.$v.emailtemplate.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        let self = this;
        self.submitted = true; //Disable Button
        const postData = {
          title: self.emailtemplate.title,
          message: self.emailtemplate.message,
          subject: self.emailtemplate.subject,
          status: self.emailtemplate.status.value,
        };
        axios
            .post(this.updateUrlweb(this.module, self.editId), postData)
            .then((response) => {
              if (response.data.code === 200) {
                self.err_msg = response.data.message;
                localStorage.setItem("notificationType", "success");
                localStorage.setItem(
                    "notificationMessage",
                    response.data.message
                );
                self.dismissCountDown = 10;
                this.$router.push({name: "email-template"});
              } else {
                self.err_msg = response.data.response.message;
                self.dismissCountDown = 10;
                self.submitted = false; //Enable Button
              }
            })
            .catch(function (error) {
              let data;
              if (error.response.data.error) {
                data = error.response.data.error.toString();
              } else {
                data = error.response.data.message;
              }
              self.err_msg = data;
              self.dismissCountDown = 10;
              self.submitted = false; //Enable Button
            });
      }
    },
    setAlert(value) {
      this.showAlert = value;
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
  },
};
</script>
<style>
#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
